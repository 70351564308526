export const useMouseX = () => useState("mouseX", () => 0);
export const useMouseY = () => useState("mouseY", () => 0);

export const useMouseCoords = () => {
  const mouseX = useMouseX();
  const mouseY = useMouseY();

  const updateCoords = (e: MouseEvent | undefined) => {
    mouseX.value = e?.clientX || e?.pageX || mouseX.value;
    mouseY.value = e?.clientY || e?.pageY || mouseY.value;
  };

  return {
    mouseX,
    mouseY,
    updateCoords,
  };
};
