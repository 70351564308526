import { devConsole } from "./../utils/logging";
import { Auth } from "~/auth";

declare module "#app" {
  interface NuxtApp {
    $auth: Auth;
  }
}

declare module "vue" {
  interface ComponentCustomProperties {
    $auth: Auth;
  }
}

export default defineNuxtPlugin({
  name: "auth",
  dependsOn: ["settings"],
  async setup() {
    const ckDefaults = useCkDefaults();
    const auth = ckDefaults.getAuthProvider();
    const localePath = useLocalePath();
    await auth.initialize();

    addRouteMiddleware(
      "auth",
      async (to) => {
        devConsole.log("auth middleware called");
        const isAuthed = await auth.isAuthed();
        if (auth) {
          // Need login
          if (!isAuthed && !to.meta.allowAnonymous && !to.path.includes("/login")) {
            return navigateTo(localePath("/login"));
          } else {
            devConsole.log("Authenticated");
            // Tell Sentry the user
            const accounts = await auth.getAccounts();
            if (accounts && accounts.length > 0) {
              devConsole.log(`Auth found ${accounts.length} accounts.`);
              // setUser({
              //   fullName: accounts[0].name,
              //   id: accounts[0].id,
              //   username: accounts[0].username,
              // });
            }
          }
        }
      },
      { global: true },
    );

    return {
      provide: {
        auth,
      },
    };
  },
});
