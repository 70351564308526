import BaseAuthClass from "~/auth/-base-auth-class";
import {
  type PopupRequest,
  type Configuration,
  type IPublicClientApplication,
  type AccountInfo,
  type AuthenticationResult,
  BrowserAuthError,
} from "@azure/msal-browser";
import type { RuntimeConfig } from "nuxt/schema";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  EventType,
  BrowserCacheLocation,
  BrowserAuthErrorCodes,
} from "@azure/msal-browser";
import { TimeInMS } from "~/utils/dateTimeHelpers";

export default class MsalAuthClass extends BaseAuthClass {
  #msalConfig: Configuration;
  #msalInstance: IPublicClientApplication;
  #msalAccount: AccountInfo | undefined;
  #msalToken: AuthenticationResult | undefined;
  #tokenPromise: Promise<AuthenticationResult> | undefined;
  #getAPIScopes(): string[] {
    return [
      this.config.public.AZURE_AD_SCOPES === ""
        ? `api://${this.config.public.AZURE_AD_CLIENT_ID}/ListPlants`
        : this.config.public.AZURE_AD_SCOPES,
    ];
  }

  constructor(config: RuntimeConfig) {
    super(config);
    this.#msalConfig = {
      auth: {
        clientId: config.public.AZURE_AD_CLIENT_ID,
        authority: "https://login.microsoftonline.com/" + config.public.AZURE_AD_TENANT_ID,
        protocolMode: "AAD",
        redirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: config.public.REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registrationregistration
        //mainWindowRedirectUri: config.public.redirectUri, // Must be registered as a SPA redirectURI on your app registrationregistration
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
        claimsBasedCachingEnabled: true, // Maintains v2 behavior
        //storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };
    this.#msalInstance = new PublicClientApplication(this.#msalConfig);
    this.#msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as PopupRequest;
        const account = payload.account!;
        this.#msalAccount = account;
        this.#msalInstance.setActiveAccount(account);
      }
    });
    this.#msalAccount = undefined;
    this.#msalToken = undefined;
    this.#tokenPromise = undefined;
  }
  async initialize() {
    await this.#msalInstance.initialize();
    await this.#msalInstance.handleRedirectPromise();
    const accounts = this.#msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      await this.getToken();
    }
  }

  async login() {
    this.#msalToken = await this.#msalInstance
      .loginPopup({
        redirectUri: this.#msalConfig.auth.redirectUri || "/",
        scopes: this.#getAPIScopes(),
      })
      .catch(async (err: BrowserAuthError) => {
        if (err.errorCode === BrowserAuthErrorCodes.popupWindowError) {
          await this.#msalInstance.loginRedirect({
            redirectUri: this.#msalConfig.auth.redirectUri || "/",
            scopes: this.#getAPIScopes(),
          });
          return undefined;
        }
        throw err;
      });
    this.#msalAccount = this.#msalToken?.account;
    if (this.#msalAccount) this.#msalInstance.setActiveAccount(this.#msalAccount);
    return !!this.#msalAccount;
  }

  async logout() {
    await this.#msalInstance.handleRedirectPromise();

    await this.#msalInstance
      .logoutPopup({
        postLogoutRedirectUri: "/",
      })
      .catch((err) => {
        if (err.errorCode === BrowserAuthErrorCodes.popupWindowError) {
          this.#msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
        throw err;
      });
    const accounts = this.#msalInstance.getAllAccounts();

    return accounts.length < 1;
  }

  async refreshToken() {
    await this.#silentlyGetToken();
    return this.#msalToken?.accessToken ?? "";
  }

  async #silentlyGetToken() {
    const tokenConfig = {
      scopes: this.#getAPIScopes(),
    };
    try {
      this.#tokenPromise = this.#msalInstance.acquireTokenSilent(tokenConfig);
      this.#msalToken = await this.#tokenPromise;
      this.#tokenPromise = undefined;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        await this.#msalInstance.acquireTokenRedirect(tokenConfig);
      } else {
        throw e;
      }
    }
  }

  #isExpired() {
    // Give ourselves a minute of leeway time to refresh the auth token
    const currentTime = new Date(Date.now() - TimeInMS.ONE_MINUTE);
    // if there isn't a date, use now, so we can assume things have expired.
    const expiresOnDate = this.#msalToken?.expiresOn instanceof Date ? this.#msalToken.expiresOn : new Date();
    return expiresOnDate <= currentTime;
  }

  async getToken() {
    if (this.#tokenPromise) await this.#tokenPromise;
    if (!this.#msalToken) {
      await this.#silentlyGetToken();
    }
    if (this.#msalToken) {
      if (this.#isExpired()) {
        // this will either refresh the token silently or force a redirect through the login flow.
        await this.#silentlyGetToken();
      }
    }
    return this.#msalToken?.accessToken ?? "";
  }

  async getAccounts() {
    return this.#msalInstance.getAllAccounts().map((el) => {
      return {
        username: el.username,
        name: el.name ?? "",
        id: el.idToken ?? "",
      };
    });
  }

  async isAuthed() {
    if (this.#tokenPromise) await this.#tokenPromise;
    // this probably needs to be a bit more robust...
    return typeof this.#msalToken !== "undefined" && !this.#isExpired();
  }

  async clearData() {
    await this.#msalInstance?.clearCache();
  }
}
