import type { Writable } from "type-fest";
//import { addBreadcrumb, getClient } from "@sentry/vue";

const c = globalThis.console;
export const devConsole: Console = (Object.keys(c) as (keyof typeof c)[]).reduce(
  (acc: Writable<typeof console>, key: keyof typeof console) => {
    if (c[key] instanceof Function) {
      if (!import.meta.env.PROD && !import.meta.env.TEST) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        acc[key] = c[key];
      }
      // else if (getClient()) {
      //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   // @ts-ignore-next-line
      //   acc[key] = (...args) => {
      //     addBreadcrumb({
      //       level: "log",
      //       category: "devConsole",
      //       message: `devConsole.${key}`,
      //       data: args,
      //     });
      //   };
      //}
      else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        acc[key] = () => {};
      }
    } else {
      Object.defineProperty(acc, key, {
        enumerable: true,
        get() {
          return c[key];
        },
      });
    }
    return acc;
  },
  {} as typeof c,
);

if (import.meta.env.DEV && !import.meta.env.TEST) {
  Object.defineProperty(window, "devConsole", {
    value: devConsole,
  });
}
