import { default as bulkrgeouMDaLcMeta } from "/src/ckSetup/pages/bulk.vue?macro=true";
import { default as indexCeXGZl0jijMeta } from "/src/ckSetup/pages/index.vue?macro=true";
import { default as loginWFniq90GjDMeta } from "/src/ckBase/pages/login.vue?macro=true";
import { default as allergenspJ6Eaf0kyfMeta } from "/src/ckSetup/pages/setup/allergens.vue?macro=true";
import { default as categoriesPkloYDHVAwMeta } from "/src/ckSetup/pages/setup/categories.vue?macro=true";
import { default as cleaning_45equipment8uC89mF8hwMeta } from "/src/ckSetup/pages/setup/cleaning-equipment.vue?macro=true";
import { default as cleaning_45infrastructurepRXn4eFgEWMeta } from "/src/ckSetup/pages/setup/cleaning-infrastructure.vue?macro=true";
import { default as cleaning_45otherNBUd0BNUyqMeta } from "/src/ckSetup/pages/setup/cleaning-other.vue?macro=true";
import { default as infog7R7HAghwpMeta } from "/src/ckSetup/pages/setup/info.vue?macro=true";
import { default as product_45categoriesHWdxSeq1muMeta } from "/src/ckSetup/pages/setup/product-categories.vue?macro=true";
import { default as roomsVTZrkejpjPMeta } from "/src/ckSetup/pages/setup/rooms.vue?macro=true";
import { default as setupSteps9pJs4wetPrMeta } from "/src/ckSetup/pages/setup/setupSteps.ts?macro=true";
import { default as skills0lvLHnrk72Meta } from "/src/ckSetup/pages/setup/skills.vue?macro=true";
import { default as setuphY0ccoWTcmMeta } from "/src/ckSetup/pages/setup.vue?macro=true";
export default [
  {
    name: "bulk___en",
    path: "/bulk",
    component: () => import("/src/ckSetup/pages/bulk.vue")
  },
  {
    name: "bulk___es",
    path: "/es/bulk",
    component: () => import("/src/ckSetup/pages/bulk.vue")
  },
  {
    name: "bulk___ar",
    path: "/ar/bulk",
    component: () => import("/src/ckSetup/pages/bulk.vue")
  },
  {
    name: "bulk___ja",
    path: "/ja/bulk",
    component: () => import("/src/ckSetup/pages/bulk.vue")
  },
  {
    name: "bulk___fr",
    path: "/fr/bulk",
    component: () => import("/src/ckSetup/pages/bulk.vue")
  },
  {
    name: "bulk___hi",
    path: "/hi/bulk",
    component: () => import("/src/ckSetup/pages/bulk.vue")
  },
  {
    name: "bulk___pt",
    path: "/pt/bulk",
    component: () => import("/src/ckSetup/pages/bulk.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___hi",
    path: "/hi",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___ar",
    path: "/ar/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___ja",
    path: "/ja/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___hi",
    path: "/hi/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___pt",
    path: "/pt/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "setup___en",
    path: "/setup",
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___en",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___en",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___en",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___en",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___en",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-info___en",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-product-categories___en",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___en",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___en",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___en",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___es",
    path: "/es/setup",
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___es",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___es",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___es",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___es",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___es",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-info___es",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-product-categories___es",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___es",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___es",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___es",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___ar",
    path: "/ar/setup",
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___ar",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___ar",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___ar",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___ar",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___ar",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-info___ar",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-product-categories___ar",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___ar",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___ar",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___ar",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___ja",
    path: "/ja/setup",
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___ja",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___ja",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___ja",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___ja",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___ja",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-info___ja",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-product-categories___ja",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___ja",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___ja",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___ja",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___fr",
    path: "/fr/setup",
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___fr",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___fr",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___fr",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___fr",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___fr",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-info___fr",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-product-categories___fr",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___fr",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___fr",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___fr",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___hi",
    path: "/hi/setup",
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___hi",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___hi",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___hi",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___hi",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___hi",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-info___hi",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-product-categories___hi",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___hi",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___hi",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___hi",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___pt",
    path: "/pt/setup",
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___pt",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___pt",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___pt",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___pt",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___pt",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-info___pt",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-product-categories___pt",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___pt",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___pt",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___pt",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  }
]