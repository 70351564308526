//import { useGlobalNotification } from "~/store/global-notification.ts";

export default defineNuxtPlugin({
  name: "errorHandler",
  dependsOn: ["settings"],
  setup(nuxtApp) {
    //const { captureException, setContext } = useReportError();
    //const globalNotification = useGlobalNotification();
    nuxtApp.vueApp.config.errorHandler = (error) => {
      //}, context) => {
      //const err = error as Error;
      //setContext("context", context);
      //captureException(err);
      //globalNotification.showError(`${err}`);
      if (import.meta.env.DEV) {
        devConsole.error(error);
      }
    };

    nuxtApp.hook("vue:error", (error) => {
      //}, context) => {
      //const err = error as Error;
      //setContext("context", context);
      //captureException(err);
      //globalNotification.showError(`${err.message} - ${err.stack!.toString()}`);
      if (import.meta.env.DEV) {
        devConsole.error(error);
      }
    });
  },
});
