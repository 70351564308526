import vClickOutside from "click-outside-vue3";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("mouse-coords", {
    created(el, binding) {
      const mouseCoordsCallback = binding.value;

      el.addEventListener("mousemove", (e) => mouseCoordsCallback(e));
      el.addEventListener("dragover", (e) => mouseCoordsCallback(e));
    },
    unmounted(el, binding) {
      const mouseCoordsCallback = binding.value;

      el.removeEventListener("mousemove", (e) => mouseCoordsCallback(e));
      el.removeEventListener("dragover", (e) => mouseCoordsCallback(e));
    },
  });

  nuxtApp.vueApp.use(vClickOutside);
});
