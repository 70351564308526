<script setup lang="ts"></script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <GlobalNotificationBar />
  </div>
</template>
