import type BaseAuthClass from "@base/auth/-base-auth-class";
import MsalAuthClass from "@base/auth/providers/msal-browser";

export function useCkDefaults(): CkDefaults {
  const config = useRuntimeConfig();

  return {
    getAuthProvider: () => new MsalAuthClass(config) as BaseAuthClass,
  };
}
