<script setup lang="ts">
defineOptions({
  name: "BaseIcon",
});
const props = defineProps<{ name: string }>();

const ALLOWED_ICON_SETS = ["mdi", "fa6-solid", "fa6-regular", "fa6-brands"];
const prefix = props.name.split(":")[0];
if (!ALLOWED_ICON_SETS.includes(prefix)) {
  devConsole.warn(
    `Please adjust from using the "${props.name}" icon to using one from the following sets: "${ALLOWED_ICON_SETS.join('", "')}"`,
  );
}
</script>
<template>
  <Icon :name v-bind="$attrs" />
</template>

<style scoped lang="postcss"></style>
