import payload_plugin_qLmFnukI99 from "/src/ckSetup/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/src/ckSetup/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/ckSetup/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/ckSetup/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/ckSetup/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/ckSetup/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/ckSetup/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/ckSetup/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/ckSetup/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/ckSetup/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/ckSetup/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_slrX03DHlI from "/src/ckBase/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_xaKWe2q0ze from "/src/ckBase/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2HDO2PcBr5 from "/src/ckBase/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_LmBgY5XgrZ from "/src/ckBase/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_TTTL0VG2Jg from "/src/ckBase/plugins/auth.ts";
import directives_erBAmAU6an from "/src/ckBase/plugins/directives.ts";
import errorHandler_7Xn65XGisv from "/src/ckBase/plugins/errorHandler.ts";
import settings_VvS11YiySN from "/src/ckBase/plugins/settings.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_slrX03DHlI,
  switch_locale_path_ssr_xaKWe2q0ze,
  i18n_2HDO2PcBr5,
  plugin_LmBgY5XgrZ,
  auth_TTTL0VG2Jg,
  directives_erBAmAU6an,
  errorHandler_7Xn65XGisv,
  settings_VvS11YiySN
]